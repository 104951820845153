"use strict";
exports.__esModule = true;
exports.extractGroupName = void 0;
var EMAIL_PROVIDERS = [
    'gmail.com',
    'icloud.com',
    'googlemail.com',
    'yahoo.com',
    'live.com',
    'hotmail.com',
    'outlook.com',
    'msn.com',
    'aol.com',
    'comcast.net',
    'sbcglobal.net',
    'att.net',
    'verizon.net',
    'shaw.ca',
    'bell.ca',
    'shawcable.ca',
    'shawcable.net',
    'shaw.net',
    'shaw.com',
    'shawcable.com',
];
var extractGroupName = function (email, fallbackToEmail) {
    if (fallbackToEmail === void 0) { fallbackToEmail = true; }
    if (!email)
        return null;
    var domain = email.split('@')[1];
    if (EMAIL_PROVIDERS.includes(domain)) {
        return fallbackToEmail ? email : null;
    }
    return domain;
};
exports.extractGroupName = extractGroupName;
