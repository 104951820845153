import { ModelDefaults } from 'src/types'

export const getSubfield = <
  Obj extends Record<string, any>,
  FieldName extends keyof Obj,
  SubfieldName extends keyof Required<NonNullable<Obj[FieldName]>>
>(
  obj: Obj | null,
  fieldName: FieldName,
  subfieldName: SubfieldName
) => {
  const field = obj?.[fieldName]
  if (!field) return undefined

  // Workaround for https://github.com/microsoft/TypeScript/issues/21760
  const subfield = (field as Required<typeof field>)[subfieldName]
  return subfield ?? undefined
}

export const setOrDeleteSubfield = <
  Obj extends Record<string, any>,
  FieldName extends keyof Obj,
  SubfieldName extends keyof Required<NonNullable<Obj[FieldName]>>,
  Value extends Required<NonNullable<Obj[FieldName]>>[SubfieldName] | undefined
>(
  obj: (Obj & ModelDefaults<Obj>) | null,
  fieldName: FieldName,
  subfieldName: SubfieldName,
  newValue: Value,
  userId: string
) => {
  if (!obj) return Promise.resolve(false)

  const updatedField: NonNullable<Obj[FieldName]> = {
    ...((obj[fieldName] ?? {}) as Obj[FieldName]),
    [subfieldName]: newValue,
  }

  if (!newValue) {
    // Workaround for https://github.com/microsoft/TypeScript/issues/21760
    delete (updatedField as Required<typeof updatedField>)[subfieldName]
  }

  return obj.update(
    {
      [fieldName]: updatedField,
    } as Obj[FieldName],
    userId
  )
}
