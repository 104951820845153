import { Feature } from '@arcadehq/shared/types'
import { Flow } from 'src/types'

import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface Watermark {
  mayToggle: boolean

  showInEditor: (flow?: Flow) => boolean
  showPaywallInEditor: (flow: Flow) => boolean

  showByDefault: boolean
  linkUrl: string | undefined
  imageUrl: string | undefined
  hoverText: string | undefined

  setShowByDefault: (showByDefault: boolean) => Promise<boolean>
  setLinkUrl: (linkUrl?: string) => Promise<boolean>
  setImageUrl: (imageUrl?: string) => Promise<boolean>
  setHoverText: (hoverText?: string) => Promise<boolean>
}

export const watermark: AccountMixin<Watermark> = (
  core,
  { team, userProfile, plan }
) => ({
  get mayToggle(): boolean {
    return plan !== 'Free'
  },

  showInEditor(flow?: Flow) {
    if (!flow) return true

    // TODO materialize entitlements on the base class
    const isShowArcadeButtonNullOrUndefined =
      flow.showArcadeButton === null || flow.showArcadeButton === undefined
    switch (plan) {
      case 'Free':
      case 'Free (Growth Churned)':
      case 'Free (Growth Trial Ended)':
      case 'Free (Pro Churned)':
      case 'Free (Pro Churned) (Growth Churned)':
      case 'Free (Pro Churned) (Growth Trial Ended)':
        return true
      case 'Pro':
      case 'Pro (Growth Churned)':
      case 'Pro (Growth Trial Ended)':
        return isShowArcadeButtonNullOrUndefined
          ? !userProfile.features?.[Feature.NoArcadeBranding]
          : flow.showArcadeButton !== false
      case 'Growth':
      case 'Growth Trial':
        if (isShowArcadeButtonNullOrUndefined && flow.belongsToTeam && team) {
          return !team.features[Feature.NoArcadeBranding]
        }
        return flow.showArcadeButton !== false
    }
  },

  showPaywallInEditor(flow: Flow) {
    switch (plan) {
      case 'Free':
      case 'Free (Growth Churned)':
      case 'Free (Growth Trial Ended)':
      case 'Free (Pro Churned)':
      case 'Free (Pro Churned) (Growth Churned)':
      case 'Free (Pro Churned) (Growth Trial Ended)':
        return true
      case 'Pro':
      case 'Pro (Growth Churned)':
      case 'Pro (Growth Trial Ended)':
        return true
      case 'Growth':
      case 'Growth Trial':
        return flow.belongsToTeam
    }
  },

  get showByDefault() {
    if (team) {
      return !getSubfield(team, 'features', Feature.NoArcadeBranding)
    }
    // TODO do Pro users have a default?
    return true
  },

  setShowByDefault(showByDefault: boolean) {
    if (team) {
      setOrDeleteSubfield(
        team,
        'features',
        Feature.NoArcadeBranding,
        !showByDefault,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  get linkUrl() {
    return getSubfield(team, 'watermark', 'link')
  },

  get imageUrl() {
    return getSubfield(team, 'watermark', 'imageUrl')
  },

  get hoverText() {
    return getSubfield(team, 'watermark', 'text')
  },

  setLinkUrl(linkUrl: string | undefined) {
    return setOrDeleteSubfield(team, 'watermark', 'link', linkUrl, core.userId)
  },

  setImageUrl(imageUrl: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'watermark',
      'imageUrl',
      imageUrl,
      core.userId
    )
  },

  setHoverText(hoverText: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'watermark',
      'text',
      hoverText,
      core.userId
    )
  },
})
