import omit from 'lodash/omit'
import { useMemo } from 'react'
import { ModelDefaults } from 'src/types'

import { captureError } from './exceptions'

type ArcadeSerializableDateObject = {
  __arcadeIsoDate: string
}

export type SerializableData<Data extends object> = {
  [K in keyof Data as Exclude<K, 'update' | 'delete'>]: Data[K] extends Date
    ? ArcadeSerializableDateObject
    : Data[K]
}

export type WithEntityHelpers<Data extends object> = Data & {
  update: ModelDefaults<Data>['update']
  delete: ModelDefaults<Data>['delete']
}

export function getSerializable<Data extends object>(
  data: Data
): SerializableData<Data> {
  return omit(
    Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value instanceof Date) {
          return [key, { __arcadeIsoDate: value.toISOString() }]
        }
        // This is not ideal. The ideal is:
        // if (value instanceof Timestamp) {
        // but importing Timestamp from firebase-admin causes other issues
        if (
          typeof value === 'object' &&
          value?._nanoseconds &&
          value?._seconds
        ) {
          return [key, { __arcadeIsoDate: value.toDate().toISOString() }]
        }
        return [key, value]
      })
    ),
    'update',
    'delete'
  ) as SerializableData<Data>
}

export function getFromSerialized<Data extends object>(
  data: SerializableData<Data>
): WithEntityHelpers<Data> {
  return {
    ...Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value && typeof value === 'object' && '__arcadeIsoDate' in value) {
          return [key, new Date(value.__arcadeIsoDate as string)]
        }
        return [key, value]
      })
    ),
    update: async () => {
      captureError('getFromSerialized', {
        error:
          'Object from getServerSideProps has been used for an update, but this connection to the database has not been done yet. Make sure to wait for `loading` to be false before using the object for an update.',
        data,
      })
      return false
    },
    delete: async () => {
      captureError('getFromSerialized', {
        error:
          'Object from getServerSideProps has been used for a delete, but this connection to the database has not been done yet. Make sure to wait for `loading` to be false before using the object for a delete.',
        data,
      })
    },
  } as unknown as WithEntityHelpers<Data>
}

export function useSerializedData<Data extends object>(
  data: SerializableData<Data> | null
): Data | null {
  return useMemo(() => (data ? getFromSerialized(data) : null), [data])
}
