import { AccountMixin } from '../AccountCore'

export interface Publishing {
  isLimited: boolean
  limit: number
  mayPublish(publishedFlowsCount: number): boolean
}

export const publishing: AccountMixin<Publishing> = (_core, { plan }) => ({
  get isLimited() {
    return plan === 'Free'
  },

  get limit() {
    // TODO default paywall limit (feature flag is unused)
    return 3
  },

  mayPublish(publishedFlowsCount: number) {
    return this.isLimited ? publishedFlowsCount < this.limit : true
  },
})

// TODO maybe it's something more general about flows
/*

mayEditFlow
mayDeleteFlow

.editing
.sharing




 */
